@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');

body {
    background: #000000;
    font-family: Karla;
    margin: 0;
}

.el-iframe {
    border: none;
}

button {
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    margin: 0 4px;
    outline: 0;
    font-size: 25px;
    border-radius: 4px;
    font-family: Karla;
}
  
.copy:hover {
    cursor: pointer;
    background-color: #fff;
    color: rgba(0,0,0,.6);

}

button:focus {
    outline: 0;
}

.container {
    height: 100vh;
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.loader {
    color: #fff;
    height: 100vh;
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-size: 30px;
}

@media only screen and (min-width: 768px) {
    .container {
        height: 100vh;
        display: flex;
        overflow: hidden;
        width: 100%;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-content: space-around;
    }
    button { 
        margin: 5px 5px;
    }
}